<template>
  <LazySearchMobileFilters
    v-if="mobileSearchFiltersLoaded"
    :show-form="showMobileSearchFilters"
    @close="showMobileSearchFilters = false"
  />
  <button type="button" :class="['mobile-filters', { active: activeFiltersCount > 0 }]" @click="openMobileFilters()">
    <IconFilters />
    <template v-if="activeFiltersCount > 0"> ({{ activeFiltersCount }})</template>
  </button>
</template>

<script lang="ts" setup>
import { AppProviderKeys } from '~/providers'
import type { IPSearchParameters } from '~/types/provider/search-parameters'

const sp = inject<IPSearchParameters>(AppProviderKeys.SearchParameters) as IPSearchParameters
const filters = computed(() => sp.searchParameters.filters)

const mobileSearchFiltersLoaded = ref(false)
const showMobileSearchFilters = ref(false)

const activeFiltersCount = computed((): number => {
  let count = 0

  if (Object.values(filters.value?.drivable ?? {}).filter((x) => x).length > 0) {
    count++
  }

  if (Object.values(filters.value?.towable ?? {}).filter((x) => x).length > 0) {
    count++
  }

  if (Object.values(filters.value?.rvPrice ?? {}).filter((x) => x).length > 0) {
    count++
  }

  if (Object.values(filters.value?.rvWeight ?? {}).filter((x) => x).length > 0) {
    count++
  }

  if (Object.values(filters.value?.rvLength ?? {}).filter((x) => x).length > 0) {
    count++
  }

  if (Object.values(filters.value?.rvYear ?? {}).filter((x) => x).length > 0) {
    count++
  }
  if (Object.values(filters.value?.sleepingCapacity ?? {}).filter((x) => x).length > 0) {
    count++
  }

  if (Object.values(filters.value?.rvBrand ?? {}).filter((x) => x).length > 0) {
    count++
  }

  return count
})

function openMobileFilters() {
  mobileSearchFiltersLoaded.value = true
  showMobileSearchFilters.value = !showMobileSearchFilters.value
}
</script>

<style lang="scss" scoped>
.mobile-filters {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.325rem;
  border: 0.0625rem solid $primary-100;
  background-color: #fff;
  color: $primary-500;
  min-width: 3rem;
  padding: 0.25rem 0.5rem;

  &.active {
    border-color: $primary-500;
  }

  svg {
    width: 1rem;
    line-height: 1.5rem;
    font-size: 1.125rem;
  }
}
</style>
