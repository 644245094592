<i18n lang="yaml">
en:
  hideMap: Hide map
  showMap: Show map

fr:
  hideMap: Cacher la carte
  showMap: Afficher la carte
</i18n>

<template>
  <div class="search-navbar flex-grow-0 flex-shrink-0">
    <div class="search-bar">
      <SearchFilters />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search-navbar {
  display: none;
  background-color: #fff;
  padding: 1rem 0;
  border-bottom: 0.0625rem solid #dcdcdc;

  @media (min-width: 768px) {
    display: block;
  }
}

.search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}
</style>
