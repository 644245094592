export const RvWeightDefaults = {
  min: 0,
  max: 20000
}

export const RvYearDefaults = {
  min: new Date().getUTCFullYear() - 20,
  max: new Date().getUTCFullYear() + 1
}

export const SleepingCapacityDefaults = {
  min: 1,
  max: 14
}

export const RvPriceDefaults = {
  min: 0,
  max: 500_000
}

export const RvLengthDefaults = {
  min: 0,
  max: 15
}
