<i18n lang="yaml">
en:
  clearAll: Clear all
  apply: Apply

fr:
  clearAll: Tout effacer
  apply: Appliquer
</i18n>

<template>
  <CommonInputButton size="sm" link variant="primary-350" @click="clear">{{ t('clearAll') }}</CommonInputButton>
  <CommonInputButton size="sm" :data-testid="applyDataTestid ?? undefined" @click="apply">{{
    t('apply')
  }}</CommonInputButton>
</template>

<script lang="ts" setup>
defineProps<{
  applyDataTestid?: string
}>()

const { t } = useI18n({ useScope: 'local' })

const apply = () => {
  emit('apply')
}

const clear = () => {
  emit('clear')
}

const emit = defineEmits<{
  apply: [value: void]
  clear: [value: void]
}>()
</script>
