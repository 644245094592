<i18n lang="yaml" src="~/i18n/filter.yml"></i18n>

<template>
  <div class="filters-header">
    <div class="filters">
      <SearchFilterToggle :active="Boolean(rvTypeCount)">
        {{ t('rvTypes.title') }}
        <template v-if="Boolean(rvTypeCount)"> ({{ rvTypeCount }})</template>
        <template #popover="{ togglePopover }">
          <LazySearchFilterRvType :value="filters?.rvType" v-slot="slotProps">
            <div class="filter-buttons">
              <SearchFilterFormButtons
                :model="slotProps.model"
                apply-data-testid="popover-apply"
                @apply="
                  () => {
                    changed('rvType', slotProps.model)
                    togglePopover()
                  }
                "
                @clear="
                  () => {
                    changed('rvType', {})
                    togglePopover()
                  }
                "
              />
            </div>
          </LazySearchFilterRvType>
        </template>
      </SearchFilterToggle>

      <SearchFilterToggle :active="brandChanged">
        {{ t('brand.title') }}
        <template #popover="{ togglePopover }">
          <p>
            <strong>{{ t('brand.title') }}</strong>
          </p>

          <LazySearchFilterRvBrand v-slot="slotProps" :model-value="filters?.rvBrand">
            <div class="filter-buttons">
              <SearchFilterFormButtons
                @apply="
                  () => {
                    changed('rvBrand', slotProps.modelValue)
                    togglePopover()
                  }
                "
                @clear="
                  () => {
                    changed('rvBrand', undefined)
                    togglePopover()
                  }
                "
              />
            </div>
          </LazySearchFilterRvBrand>
        </template>
      </SearchFilterToggle>

      <SearchFilterToggle :active="priceChanged">
        {{ t('price.title') }}
        <template #popover="{ togglePopover }">
          <div>
            <p>
              <strong>{{ t('price.range') }}</strong>
            </p>

            <LazySearchFilterRvPrice
              :min="filters?.rvPrice?.min"
              :max="filters?.rvPrice?.max"
              show-fields
              with-tooltips
              v-slot="slotProps"
            >
              <div class="filter-buttons">
                <SearchFilterFormButtons
                  :model-value="slotProps.modelValue"
                  @apply="
                    () => {
                      changed('rvPrice', {
                        min: slotProps.modelValue[0] > RvPriceDefaults.min ? slotProps.modelValue[0] : undefined,
                        max: slotProps.modelValue[1] < RvPriceDefaults.max ? slotProps.modelValue[1] : undefined
                      })
                      togglePopover()
                    }
                  "
                  @clear="
                    () => {
                      changed('rvPrice', { min: undefined, max: undefined })
                      togglePopover()
                    }
                  "
                />
              </div>
            </LazySearchFilterRvPrice>
          </div>
        </template>
      </SearchFilterToggle>

      <SearchFilterToggle>
        {{ moreFiltersLabel }}
        <template #popover="{ togglePopover }">
          <p>
            <strong>{{ t('moreFilters') }}</strong>
          </p>
          <LazySearchFilterMoreFilters
            @close="
              (n: number) => {
                numMoreFiltersSelected = n
                togglePopover()
              }
            "
          />
        </template>
      </SearchFilterToggle>

      <CommonInputButton size="sm" link variant="primary-350" @click="clearAll">{{ t('clearAll') }}</CommonInputButton>
    </div>
    <SearchSort v-if="isLargeScreen" />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { RvPriceDefaults } from '~/lib/defaults'
import { AppProviderKeys } from '~/providers'
import type { IPSearchParameters } from '~/types/provider/search-parameters'
import type { ISearchFilters } from '~/types/search/filters'

const { t } = useI18n({ useScope: 'local' })

const isLargeScreen = useIsLargeScreen()

const sp = inject<IPSearchParameters>(AppProviderKeys.SearchParameters) as IPSearchParameters
const filters = computed(() => sp.searchParameters.filters)

const rvTypeCount = computed((): number => Object.values(filters.value?.rvType ?? {}).filter((type) => type).length)

const priceChanged = computed((): boolean =>
  Boolean(
    (filters.value?.rvPrice?.min && filters.value?.rvPrice.min > RvPriceDefaults.min) ||
      (filters.value?.rvPrice?.max && filters.value?.rvPrice.max < RvPriceDefaults.max)
  )
)

const brandChanged = computed(() => {
  return Boolean(filters.value?.rvBrand)
})

const numMoreFiltersSelected = ref<number>(0)
const moreFiltersLabel = computed(() => {
  return `${t('moreFilters')}${numMoreFiltersSelected.value > 0 ? `(${numMoreFiltersSelected.value})` : ''}`
})

const activeFiltersCount = computed((): number => {
  let count = 0

  if (process.server) {
    return count
  }

  if (!isLargeScreen.value) {
    if (Object.values(filters.value?.rvPrice ?? {}).filter((x) => x).length > 0) {
      count++
    }
  }

  if (Object.values(filters.value?.rvWeight ?? {}).filter((x) => x).length > 0) {
    count++
  }

  if (Object.values(filters.value?.rvLength ?? {}).filter((x) => x).length > 0) {
    count++
  }

  if (Object.values(filters.value?.rvYear ?? {}).filter((x) => x).length > 0) {
    count++
  }

  if (Object.values(filters.value?.rvBrand ?? {}).filter((x) => x).length > 0) {
    count++
  }

  if (Object.values(filters.value?.sleepingCapacity ?? {}).filter((x) => x).length > 0) {
    count++
  }

  return count
})

const moreFiltersKey = ref(-1)

onMounted(() => {
  moreFiltersKey.value = activeFiltersCount.value
})

const changed = (filter: keyof ISearchFilters, value: any) => {
  sp.updateFilter(filter, value)
}

const clearAll = () => {
  sp.updateFilters({})
  numMoreFiltersSelected.value = 0
}
</script>

<style lang="scss" scoped>
.filters-header {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1rem;
  }
}
</style>
