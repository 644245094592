<template>
  <CommonInputButton
    ref="buttonEl"
    class="toogle-button"
    v-bind="$attrs"
    :class="['filter-btn', { active, opened: showPopover }]"
    @click="togglePopover()"
  >
    <slot />
    <CommonChevronToggle :shown="showPopover" />
  </CommonInputButton>

  <div
    v-if="showPopover"
    ref="floatingEl"
    :class="['floating', { 'half-width': !props.autoWidth }]"
    :style="floatingStyles"
  >
    <slot name="popover" :togglePopover="togglePopover" />
  </div>
</template>

<script lang="ts" setup>
import { useFloating, offset, shift, autoUpdate } from '@floating-ui/vue'
import { onClickOutside } from '@vueuse/core'
import { CommonInputButton } from '#components'

const props = defineProps<{
  active?: boolean
  autoWidth?: boolean
}>()

const buttonEl = ref<InstanceType<typeof CommonInputButton>>()
const floatingEl = ref<HTMLDivElement>()

const { floatingStyles } = useFloating(buttonEl, floatingEl, {
  placement: 'bottom-start',
  middleware: [offset(8), shift()],
  whileElementsMounted: autoUpdate
})

const showPopover = ref(false)

const togglePopover = () => (showPopover.value = !showPopover.value)

onClickOutside(floatingEl, (event) => {
  if (!event.composedPath().includes(buttonEl.value?.$el)) {
    togglePopover()
  }
})
</script>

<style lang="scss" scoped>
:deep(.chevron) {
  width: 0.75rem;
  height: auto;
  vertical-align: text-bottom;
  margin-left: 0.325rem;
}
.floating {
  &.half-width {
    max-width: min(50vw, 640px);
    width: 50vw;
  }
}
.toogle-button {
  display: flex;
}
</style>
