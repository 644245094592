<template>
  <NuxtLayout name="base">
    <div class="flex flex-col min-h-dvh">
      <AppHeader>
        <template #mobile-filters>
          <SearchMobileFiltersToggle v-if="!isLargeScreen" />
        </template>
      </AppHeader>
      <SearchHeader v-if="isLargeScreen" />
      <div class="flex-grow min-w-0">
        <slot />
      </div>
    </div>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import clone from 'clone'
import { extractSearchQuery } from '~/lib/query'
import { queryStringToSearchFilters, queryStringToSortOrder, searchParametersToSearchQuery } from '~/lib/search'
import { AppProviderKeys } from '~/providers'
import type { RvSearchSorts } from '~/types/api'
import type { IGooglePlace } from '~/types/common/google-place'
import type { IPagination } from '~/types/pagination'
import type { IPPagination } from '~/types/provider/pagination'
import type { IPSearchParameters } from '~/types/provider/search-parameters'
import type { ISearchFilters } from '~/types/search/filters'
import type { ISearchParameters } from '~/types/search/search'

const route = useRoute()
const isLargeScreen = useIsLargeScreen()
const query = extractSearchQuery(route.query)

const searchParameters: ISearchParameters = reactive({
  filters: queryStringToSearchFilters(query),
  sort: queryStringToSortOrder(query)
})

const pagination: IPagination = reactive({
  currentPage: 1,
  itemCount: 0,
  itemsPerPage: 30,
  totalPages: 0
})

provide<IPSearchParameters>(AppProviderKeys.SearchParameters, {
  searchParameters,
  updateFilters: (value: ISearchFilters): void => {
    searchParameters.filters = clone(value)
  },
  updateFilter: (key: keyof ISearchFilters, value: any): void => {
    if (!searchParameters.filters) searchParameters.filters = {}
    searchParameters.filters[key] = clone(value)
  },
  updateSortOrder: (value: RvSearchSorts): void => {
    searchParameters.sort = clone(value)
  },
  updateLocation: (value?: IGooglePlace): void => {
    searchParameters.location = value
  }
})

provide<IPPagination>(AppProviderKeys.Pagination, {
  value: pagination,
  updateCurrentPage: (value: number) => {
    pagination.currentPage = value
  }
})

watch(
  () => searchParameters.sort,
  () => {
    addQueryParams()
  },
  { deep: true }
)

watch(
  () => searchParameters.filters,
  () => {
    addQueryParams()
  },
  { deep: true }
)

const addQueryParams = () => {
  const searchQuery = searchParametersToSearchQuery(searchParameters)
  const params: any = { ...searchQuery }
  params.Types = params.Types.length ? params.Types.join(',') : undefined

  const filtered: Record<string, any> = Object.keys(params)
    .filter((x: string) => params[x] != null)
    .reduce((acc: any, x) => {
      if (params[x] != null && params[x] !== '') acc[x] = String(params[x])
      return acc
    }, {})

  const query = new URLSearchParams(filtered).toString().replace(/=true(&|$)/g, '$1')

  if (query) navigateTo(`${route.path}?${query}`, { replace: true })
  else navigateTo(route.path)
}
</script>
